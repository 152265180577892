<template>
  <div class="service-times-presentations template-2">
    <page-header class="mx-lg">
      <h1>{{ translations.tcChurchDetails }}</h1>
      <BackJump @reload_host="pageLoad()" :obj="backJumpObj" :vis="true" :i18n="translations.components"></BackJump>
    </page-header>

    <page-body class="mx-lg">
      <section class="section-1">
        <div class="bg-block p-4">
          <header class="mb-2">
            <h4>{{ detail.org_name }}</h4>
          </header>
          <div v-if="translations.tcYes" class="d-flex row details">
            <div class="col">
              <div class="detail">
                <strong>{{ translations.tcAssignedNumber }}</strong>
                <span>{{ detail.camp_assigned_church_number }}</span>
              </div>
              <div class="detail">
                <strong>{{ translations.tcDenomination }}:</strong>
                <span>{{ detail.denomination }}</span>
              </div>
              <div class="detail">
                <strong>{{ translations.tcWorshipAttendance }}:</strong>
                <span>{{ detail.estimated_worship_attendance }}</span>
              </div>
              <div class="detail">
                <strong>{{ translations.tcGideonCardDisplays }}:</strong>
                <span>{{ detail.gideoncard_display_number }}</span>
              </div>
              <div class="detail">
                <strong>{{ translations.tcPreferredLanguage }}:</strong>
                <span>{{ detail.church_lang_type }}</span>
              </div>
              <div class="detail">
                <strong>{{ translations.tcPastorBiVocational }}:</strong>
                <span>{{ detail.pastor_bi_vocational_flag ? Yes : No }}</span>
              </div>
              <div class="detail">
                <strong>{{ translations.tcRadioTVBroadcast }}:</strong>
                <span>{{ detail.radio_television_broadcast_flag ? Yes : No }}</span>
              </div>
              <div class="detail">
                <strong>{{ translations.tcInChurchBudget }}:</strong>
                <span>{{ detail.in_church_budget ? Yes : No }}</span>
              </div>
              <div class="detail">
                <strong>{{ translations.tcLifeBook }}:</strong>
                <span>{{ detail.life_book ? Yes : No }}</span>
              </div>
            </div>
            <div class="col">
              <div class="detail">
                <strong>{{ translations.tcRelationshipStrength }}:</strong>
                <span>{{ detail.church_relationship_strength }}</span>
              </div>
              <div class="detail">
                <strong>{{ translations.tcSupportLevel }}:</strong>
                <span>{{ detail.church_level_support }}</span>
              </div>
              <div class="detail">
                <strong>{{ translations.tcRank }}:</strong>
                <span>{{ detail.church_ranking }}</span>
              </div>
              <div class="detail">
                <strong>{{ translations.tcWhereWeShouldBe }}:</strong>
                <span>{{ detail.where_we_should_be_flag ? Yes : No }}</span>
              </div>
              <div class="detail">
                <strong>{{ translations.tcGideonDisplayServiced }}:</strong>
                <span>{{ last_restock }}</span>
              </div>
              <div class="detail">
                <strong>{{ translations.tcLastGideonCardVideoPresentation }}:</strong>
                <span>{{ last_video_showing }}</span>
              </div>
              <div class="detail full-detail">
                <strong>{{ translations.tcWebsite }}:</strong>
                <span>
                  <a target="_blank" :href="websiteURL" v-if="detail.church_url">{{ detail.church_url }}</a>
                </span>
              </div>
            </div>
            <div class="col">
              <div class="detail">
                <strong>{{ translations.tcShowOnline }}:</strong>
                <span>{{ detail.show_online_flag ? Yes : No }}</span>
              </div>
              <div class="detail">
                <strong>{{ translations.tcScriptureList || `Scripture List` }}:</strong>
                <span>{{ detail.scripture_list ? Yes : No }}</span>
              </div>
              <div class="detail full-detail">
                <strong>{{ translations.tcOtherInformation }}:</strong>
                <span>{{ detail.note }}</span>
              </div>
            </div>
          </div>
          <b-button v-if="toggleMe && edit_accessible" variant="primary" @click="handleEditClick">{{
              translations.tcEdit
          }}</b-button>
          <b-button v-if="toggleMe && delete_accessible" variant="primary" class="ml-4" @click="handleDeleteClick">{{
              translations.tcDelete
          }}</b-button>
        </div>
      </section>
    </page-body>

    <page-footer class="mx-lg">
      <BackJump :obj="backJumpObj" :vis="false" :i18n="translations.components"></BackJump>
    </page-footer>
  </div>
</template>

<script>
/* eslint-disable */
import { mapGetters, mapActions } from 'vuex'
import BackJump from '@/components/back-jump/BackJump.vue'
import iTooltip from '@/assets/svgs/i-tooltip.vue'
import pageBody from '@/components/page-components/PageBody.vue'
import pageFooter from '@/components/page-components/PageFooter.vue'
import pageHeader from '@/components/page-components/PageHeader.vue'
import { securityMixin } from '@/mixins/securityMixin'
import ToggleArrow from '@/components/toggle-arrow/ToggleArrow.vue'
import { translationMixin } from '@/mixins/translationMixin'

export default {
  name: 'church-details',
  mixins: [securityMixin, translationMixin],
  data() {
    return {
      delete_accessible: false,
      edit_accessible: false,
      translations: {},
      toggle: true,
      toggleMe: true,
      backJumpObj: {
        link: '/programs/cm/church-profile',
        location: '',
        key: null,
      },
      secured_controls: {
        delete_church_details_button: '49f6e1ac-a927-4032-a61b-f4acf7994c6b',
        edit_church_details_button: '987cec00-9ddf-4d84-981a-b352e51d8915',
      },
    }
  },
  methods: {
    ...mapActions({
      deleteChurch: 'churchMinistry/deleteChurch',
      getControlPermissionsFromList: 'user/getControlPermissionsFromList',
      loadAddressFormOptions: 'membership/loadAddressFormOptions',
      loadChurchDetailSelect: 'churchMinistry/loadChurchDetailSelect',
      setLoadingStatus: 'menu/setLoadingStatus',
    }),
    async pageLoad() {
      try {
        this.setLoadingStatus(true),
          await Promise.all([
            await this.loadAddressFormOptions(),
            await this.loadChurchDetailSelect(),
            await (this.backJumpObj.location = this.detail.org_name),
          ])
      } catch (e) {
        console.error(e)
      } finally {
        this.setLoadingStatus(false)
      }
    },
    async handleEditClick() {
      this.$router.push('/programs/cm/churchProfile/edit-church')
    },
    async handleDeleteClick() {
      try {
        this.$swal({
          icon: 'question',
          showCancelButton: true,
          cancelButtonColor: '#d33',
          text: this.translations.tcChurchWillBeDeletedOkay,
          confirmButtonText: this.translations.tcOk,
          cancelButtonText: this.translations.tcCancel || 'Cancel',
        })
          .then((result) => {
            if (result.value) {
              this.setLoadingStatus(true)
              Promise.all([this.deleteChurch(this.userSelectedChurchKey)])
            }
          })
          .then(() => {
            this.$router.push('/programs/cm/church-list')
          })
      } catch (e) {
        console.error(e)
      } finally {
        this.setLoadingStatus(false)
      }
    },
    async checkPermissions() {
      await this.getControlPermissionsFromList(Object.values(this.secured_controls)).then(() => {
        this.delete_accessible = this.determineAccessibility(this.secured_controls.delete_church_details_button, true)
        this.edit_accessible = this.determineAccessibility(this.secured_controls.edit_church_details_button)
      })
    },
    updateDynamicContent() {
      // Language
      const langGuid = this.detail.chu_lnt_key || false
      this.detail.church_lang_type =
        this.translations.common['church-languages'][langGuid] || this.detail.church_lang_type

      // Support Level
      const supportGuid = this.detail.chu_chl_key || false
      this.detail.church_level_support =
        this.translations.common['support-level'][supportGuid] || this.detail.church_level_support

      // Relationship Strength
      const relationGuid = this.detail.chu_chr_key || false
      this.detail.church_relationship_strength =
        this.translations.common['relationship'][relationGuid] || this.detail.church_relationship_strength

      return true
    },
  },
  async created() {
    this.setLoadingStatus(true)
    await Promise.all([
      await this.checkPermissions(),
      await this.getTranslations(),
      await this.getComponentTranslations('back-jump', 'quick-jump'),
      await this.getComponentTranslations('common.church-languages', 'common.relationship', 'common.support-level'),
      await this.pageLoad(),
    ]).then((results) => {
      this.stripReadableText(results[3])
      const translatedText = {
        ...results[2],
        common: { ...this.translations.common },
      }
      this.$set(this.translations, 'components', translatedText)
      this.updateDynamicContent()
    })
  },
  computed: {
    ...mapGetters({
      detail: 'churchMinistry/churchDetailSelect',
      iCanSee: 'user/iCanSee',
      officerToolbarSelected: 'user/officerToolbarSelected',
      prefCulture: 'user/userPreferredCulture',
      userLogin: 'user/userLogin',
      userSelectedChurchKey: 'user/userSelectedChurchKey',
    }),
    last_presentation() {
      return !this.detail.last_presentation_date ? '' : this.detail.last_presentation_date.split(' ')[0]
    },
    last_restock() {
      return !this.detail.last_gideoncard_restock_date ? '' : this.detail.last_gideoncard_restock_date.split(' ')[0]
    },
    last_video_showing() {
      return !this.detail.last_gideon_card_video_showing ? '' : this.detail.last_gideon_card_video_showing.split(' ')[0]
    },
    No() {
      return this.translations ? this.translations.tcNo : 'No'
    },
    websiteURL() {
      if (!this.detail.church_url) {
        return ''
      }
      return this.detail.church_url.indexOf('http://') === -1 && this.detail.church_url.indexOf('https://') === -1
        ? `http://${this.detail.church_url}`
        : this.detail.church_url
    },
    Yes() {
      return this.translations ? this.translations.tcYes : 'Yes'
    },
  },
  components: {
    BackJump: BackJump,
    iTooltip: iTooltip,
    pageBody: pageBody,
    pageFooter: pageFooter,
    pageHeader: pageHeader,
    ToggleArrow: ToggleArrow,
  },
}
</script>

<style lang="scss">
@import '@/styles/settings.scss';

.service-times-presentations {
  .page-header h1 {
    margin-bottom: 50px;
  }

  h2 {
    margin: 0;
    color: #000000;
    font-family: $DIN;
    font-size: 42px;
    letter-spacing: 1.4px;
    line-height: 44px;
    text-transform: uppercase;
    display: flex;

    .i-tooltip {
      position: relative;
      top: -5px;
      margin-left: 10px;
      line-height: 0;

      svg path {
        fill: #000;
      }
    }
  }

  section {
    margin-bottom: 40px;

    .p-4 {
      padding: 40px 30px !important;

      @include breakpoint(sm) {
        padding: 20px 24px 20px 24px !important;
      }
    }

    header {
      display: flex;
    }

    @include breakpoint(sm) {
      .btn {
        width: 100%;
        margin-bottom: 20px;

        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
  }

  @include breakpoint(sm) {
    .section-1 {
      .ml-4 {
        margin-left: 0 !important;
      }
    }
  }

  .service-times,
  .pres-dates {
    margin-top: 35px;
  }

  .pres-dates {
    .date-card {
      width: calc(33.333% - 15px);
      margin-right: 15px;

      @include breakpoint(sm) {
        width: 100%;
        margin-right: 0;
      }
    }
  }
}

.details {
  margin-bottom: 50px;

  @include breakpoint(sm) {
    flex-wrap: wrap;
  }

  .col {
    flex: 0 0 33.3333%;
    width: 33.3333%;

    @include breakpoint(sm) {
      flex: 0 0 100%;
      width: 100%;
    }
  }

  .detail {
    display: flex;
    align-items: flex-start;
    font-size: 15px;

    strong,
    span {
      display: block;
      color: $gray;
      letter-spacing: 1px;
      line-height: 28px;
    }

    strong {
      flex: 0 0 auto;
      width: 210px;
      padding-right: 10px;
      text-transform: uppercase;
      font-weight: 900;
    }

    a {
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }

    span {
      flex: 1 1 auto;
      align-self: flex-end;
    }

    &.full-detail {
      display: block;
    }
  }
}
</style>
